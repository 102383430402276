<template>
  <div id="component-tower-details-assets" class="content-with-sidebar">
    <div class="sidebar">
      <div class="sidebar-card">
        <h3 class="title">
          <i class="far fa-file-alt mr-2"></i>
          Tower Data
        </h3>
        <div class="p-4">
          <table class="table table-borderless table-sm mb-0">
            <tr>
              <td
                class="text-muted text-right font-italic font-weight-light"
                style="font-size: 12px"
              >
                Coordinate
              </td>
              <td class="pl-4">{{ tower.lat }}, {{ tower.lng }}</td>
            </tr>
            <tr v-for="kv in towerData" :key="kv.k">
              <td
                class="text-muted text-right font-italic font-weight-light"
                style="font-size: 12px"
              >
                {{ kv.k }}
              </td>
              <td class="pl-4">
                {{ kv.v }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="sidebar-card" v-if="tower.company">
        <h3 class="title">
          <i class="fas fa-users mr-2"></i>
          Tower Owner
        </h3>
        <div class="media p-4">
          <img
            :src="tower.company.image.url"
            class="mr-3"
            alt="..."
            style="width: 80px"
          />
          <div class="media-body">
            <h6 class="mt-0" style="font-size: 16px">
              {{ tower.company.name }}
            </h6>
            <span class="text-muted" style="font-size: 12px">
              {{ tower.company.address }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <h3 class="title border-0">
        <i class="fas fa-sliders-h mr-2"></i>
        Filters
      </h3>
      <div class="overflow-scroll-x px-4 py-3 mb-4">
        <div class="btn-group rounded-pill" role="group">
          <button
            type="button"
            class="btn text-nowrap"
            :class="{ 'is-active': selectedTelco == null }"
            @click="filterByTelco(null)"
          >
            <span class="mr-2">{{ assets.length }}</span>
            All Assets
          </button>
          <button
            type="button"
            class="btn text-nowrap"
            :class="{
              'is-active':
                selectedTelco != null &&
                selectedTelco != 'Unaccounted' &&
                selectedTelco.id == telco.id,
            }"
            v-for="telco in telcos"
            :key="telco.id"
            @click="filterByTelco(telco)"
          >
            <span class="mr-2">{{ totalCount(telco) }}</span>
            <img :src="telco.logo.url" alt="" />
          </button>
          <button
            type="button"
            class="btn text-nowrap"
            :class="{ 'is-active': selectedTelco == 'Unaccounted' }"
            @click="filterByTelco('Unaccounted')"
          >
            <span class="mr-2">
              {{ totalUnaccounted }}
            </span>
            <span class="text-primary">Unaccounted</span>
          </button>
        </div>
      </div>
      <h3 class="title border-0">
        <i class="fas fa-broadcast-tower mr-2"></i>
        Antennas
      </h3>
      <div class="table-responsive px-4 py-3">
        <table class="table table-assets">
          <thead>
            <tr>
              <th></th>
              <th>ASSET NAME</th>
              <th>CARRIER</th>
              <th>LOCATION</th>
              <th>WIDTH (M)</th>
              <th>HEIGHT (M)</th>
              <th>DEPTH (M)</th>
              <th>BEARING (DEG)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="asset in antennas" :key="asset.id">
              <td class="text-center px-md-0">
                <button class="btn btn-link p-0" @click="openGallery(asset.id)">
                  <img :src="asset.image.url" alt="" />
                </button>
              </td>
              <td class="font-weight-bold">{{ asset.name }}</td>
              <td class="text-center px-0">
                <img v-if="asset.telco" :src="asset.telco.logo.url" alt="" />
              </td>
              <td>{{ asset.location.replace("_", " ") }}</td>
              <td>{{ asset.width ? asset.width.toFixed(2) : "n/a" }}</td>
              <td>{{ asset.height ? asset.height.toFixed(2) : "n/a" }}</td>
              <td>{{ asset.depth ? asset.depth.toFixed(2) : "n/a" }}</td>
              <td>{{ asset.bearing ? asset.bearing.toFixed(2) : "n/a" }}</td>
            </tr>
            <tr v-if="antennas.length < 1">
              <td colspan="8" class="text-center font-italic text-muted">
                No antenna found
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3 class="title border-0">
        <i class="fas fa-satellite-dish mr-2"></i>
        Dishes
      </h3>
      <div class="table-responsive px-4 py-3">
        <table class="table table-assets">
          <thead>
            <tr>
              <th></th>
              <th>ASSET NAME</th>
              <th>CARRIER</th>
              <th>LOCATION</th>
              <th>WIDTH (M)</th>
              <th>HEIGHT (M)</th>
              <th>DEPTH (M)</th>
              <th>BEARING (DEG)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="asset in dishes" :key="asset.id">
              <td class="text-center px-md-0">
                <button class="btn btn-link p-0" @click="openGallery(asset.id)">
                  <img :src="asset.image.url" alt="" />
                </button>
              </td>
              <td class="font-weight-bold">{{ asset.name }}</td>
              <td class="text-center px-0">
                <img v-if="asset.telco" :src="asset.telco.logo.url" alt="" />
              </td>
              <td>{{ asset.location.replace("_", " ") }}</td>
              <td>{{ asset.width ? asset.width.toFixed(2) : "n/a" }}</td>
              <td>{{ asset.height ? asset.height.toFixed(2) : "n/a" }}</td>
              <td>{{ asset.depth ? asset.depth.toFixed(2) : "n/a" }}</td>
              <td>{{ asset.bearing ? asset.bearing.toFixed(2) : "n/a" }}</td>
            </tr>
            <tr v-if="dishes.length < 1">
              <td colspan="8" class="text-center font-italic text-muted">
                No dish found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";

export default {
  name: "towerDetailsAssets",
  props: ["tower"],
  data() {
    return {
      assets: [],
      telcos: [],
      selectedTelco: null,
    };
  },
  methods: {
    openGallery(id) {
      this.$router.replace({
        query: {
          media_type: "assets",
          media_id: id,
        },
      });
    },
    totalCount(telco) {
      return this.assets.filter(
        (asset) => asset.telco && asset.telco.id == telco.id
      ).length;
    },
    filterByTelco(telco) {
      this.selectedTelco = telco;
    },
  },
  computed: {
    totalUnaccounted() {
      return this.assets.filter((asset) => asset.telco == null).length;
    },
    antennas() {
      if (this.assets.length == 0) return [];
      return this.assets.filter((asset) => {
        if (this.selectedTelco == null) return asset.type == "Antenna";
        if (this.selectedTelco == "Unaccounted")
          return asset.type == "Antenna" && asset.telco == null;
        return (
          asset.type == "Antenna" &&
          asset.telco &&
          asset.telco.id == this.selectedTelco.id
        );
      });
    },
    dishes() {
      if (this.assets.length == 0) return [];
      return this.assets.filter((asset) => {
        if (this.selectedTelco == null) return asset.type == "Dish";
        if (this.selectedTelco == "Unaccounted")
          return asset.type == "Dish" && asset.telco == null;
        return (
          asset.type == "Dish" &&
          asset.telco &&
          asset.telco.id == this.selectedTelco.id
        );
      });
    },
    towerData() {
      const kv = [];
      Object.keys(this.tower.tower).forEach((key) => {
        if (
          [
            "id",
            "_id",
            "createdAt",
            "updatedAt",
            "__v",
            "site",
            "ancillariesLayoutImage",
          ].includes(key)
        ) {
          return;
        }
        const result = key.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        kv.push({ k: finalResult, v: this.tower.tower[key] });
      });
      return kv;
    },
  },
  async mounted() {
    this.$parent.isLoading = true;
    this.assets = (await API.get(`assets?tower=${this.tower.tower.id}`)).data;
    this.telcos = (await API.get("telcos")).data;
    this.$parent.isLoading = false;

    if (this.$route.query && this.$route.query.telco) {
      if (this.$route.query.telco == "Unaccounted") {
        this.selectedTelco = "Unaccounted";
      } else {
        this.selectedTelco = this.telcos.find(
          (telco) => telco.id == this.$route.query.telco
        );
      }
    }
  },
};
</script>